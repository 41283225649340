import { request } from "../feathers/feathersClient"
import Constants, { templateDesignModern } from '../constants'
import BaseButton from "../components/button/button"
import { format } from "date-fns"
import { v4 as uuidv4 } from 'uuid'
import WelcomeNotice from "../components/popup/welcomeNotice"
import { Button, Stack, Typography } from "@mui/material"
import imageCompression from 'browser-image-compression'
import RegisterPopup from "../components/popup/registerPopup"
import { CleaningServices } from "@mui/icons-material"
import manifest from "./manifest.json"
import ClaimRebatePopup from "../components/popup/claimRebatePopup"

class Utils {
    static shared = new Utils()

    // Store values into local storage
    storeLocalStorage(key, value) {
        window.localStorage.setItem(key, value)
    }

    // Retrieve values into local storage
    retrieveLocalStorage(key) {
        return window.localStorage.getItem(key)
    }

    // Brighten color
    brighten(color, c) {
        const calc = (sub1, sub2) => Math.min(255, Math.floor(parseInt(color.substr(sub1, sub2), 16) * c)).toString(16).padStart(2, "0")
        return `#${calc(1, 2)}${calc(3, 2)}${calc(5, 2)}`
    }
    shadeColor(color, percent) {

        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        R = Math.round(R)
        G = Math.round(G)
        B = Math.round(B)

        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    // Format to amount
    getAmountString(amount, currency = '', companyModel = null) {
        const formatted = parseFloat(amount).toFixed(2)

        if (companyModel?.isPlayerSiteDisplayCurrency ?? true) {
            if (currency.length > 0) {
                return `${currency} ${formatted}`
            }
        }

        return formatted
    }

    // Format phone number
    getMaskedPhoneNumber(contactNumber = '') {
        let firstPart = contactNumber.length >= 4 ? contactNumber.substring(0, 2) : ''
        let secondPart = contactNumber.length >= 8 ? '******' : ''
        let lastPart = contactNumber.length >= 11 ? contactNumber.substring(8, 11) : ''
        let joined = `${firstPart}${secondPart}${lastPart}`

        return joined.length > 0 ? joined : '-'
    }

    //Format url
    getFormattedSecureURL(link = '') {
        if (link.startsWith('http://') || link.startsWith('https://')) {
            return link
        } else {
            return `https://${link}`
        }
    }

    /* IMAGES METHODS */
    getBase64FromFile(file) {
        return new Promise(resolve => {
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        })
    }
    getMessagesImages(companyInfo, fileName, roomId) {
        return `${companyInfo?.azureBlobStorageUrl}${Constants.bucket_path_messages}${roomId}/${fileName}?${companyInfo?.azureBlobSASToken}`
    }
    getCompanyImagesLink(companyInfo, fileName, companyId) {
        return `${companyInfo?.azureBlobStorageUrl}${Constants.bucket_path_companies}${companyId}/${fileName}?${companyInfo?.azureBlobSASToken}`
    }
    getCompanyGamesSequenceImagesLink(companyInfo, fileName) {
        return `${companyInfo?.azureBlobStorageUrl}${Constants.bucket_path_company_games_sequence}${fileName}?${companyInfo?.azureBlobSASToken}`
    }
    getGamesImagesLink(companyInfo, fileName) {
        return `${companyInfo?.azureBlobStorageUrl}${Constants.bucket_path_games}${fileName}?${companyInfo?.azureBlobSASToken}`
    }
    getCustomCategoryImage(companyInfo, fileName) {
        return `${companyInfo?.azureBlobStorageUrl}${Constants.bucket_path_company_custom_games}${fileName}?${companyInfo?.azureBlobSASToken}`
    }
    getBonusImagesLink(companyInfo, fileName) {
        return `${companyInfo?.azureBlobStorageUrl}${Constants.bucket_path_bonus}${companyInfo?.company?._id}/${fileName}?${companyInfo?.azureBlobSASToken}`
    }
    getCompressedFile(imageFile, setAlertDialog, onFileCompressed) {
        //Check if file is image
        if (imageFile.type.includes('image')) {
            //Image, perform compression
            var options = {
                useWebWorker: true,
                maxWidthOrHeight: 1200,
                alwaysKeepResolution: true,
            }
            imageCompression(imageFile, options).then(function (compressedFile) {
                Utils.shared.getBase64FromFile(compressedFile).then((result) => {
                    onFileCompressed(result, imageFile)
                }).catch((error) => {
                    setAlertDialog(alertDialog => ({
                        ...alertDialog,
                        description: error.message,
                        isOpen: true,
                    }))
                })
            }).catch(function (error) {
                setAlertDialog(alertDialog => ({
                    ...alertDialog,
                    description: error.message,
                    isOpen: true,
                }))
            })
        } else {
            //Other file, no compression needed
            Utils.shared.getBase64FromFile(imageFile).then((result) => {
                onFileCompressed(result, imageFile)
            }).catch((error) => {
                setAlertDialog(alertDialog => ({
                    ...alertDialog,
                    description: error.message,
                    isOpen: true,
                }))
            })
        }
    }

    /* DEVICE METHODS */
    //Get device type
    checkIfIsMobile() {
        var isMobile = false
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4))) {
            isMobile = true
        }
        return isMobile
    }

    //Get current platform
    getCurrentPlatform() {
        const userAgent = navigator.userAgent;

        if (/android-app/i.test(userAgent)) {
            return 'app';
        } else if (/android/i.test(userAgent)) {
            return 'android';
        } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
            return 'ios';
        } else {
            return 'desktop'
        }
    }

    //Get device ID and store if doesnt exist
    getDeviceID() {
        //Check if have device ID stored
        let deviceIDKey = `${Constants.keys_device_id}${window.location.host}`
        const deviceID = window.localStorage.getItem(deviceIDKey) ?? ''
        if (deviceID.length > 0) {
            //Already have stored, straight return
            return deviceID
        } else {
            //No device id, generate new and store, then return it
            const newDeviceID = uuidv4()
            window.localStorage.setItem(deviceIDKey, newDeviceID)
            return newDeviceID
        }
    }

    /* GET DATA */
    //Get player withdrawal accounts
    getPlayerWithdrawalAccounts(companyInfo, userInfo, setAlertDialog, onRetrieved, showLoading = true) {
        const client = request({
            setAlertDialog: setAlertDialog
        })
        client.create('get-player-withdrawal-accounts', {
            company: companyInfo?.company?._id,
            user: userInfo?._id
        }, null, (response) => {
            const withdrawalBanks = response.withdrawalBanks ?? []
            const playerWithdrawalAccounts = response.playerWithdrawalAccounts ?? []

            onRetrieved(withdrawalBanks, playerWithdrawalAccounts)
        }, null, showLoading)
    }

    /* DATES */
    //Get date only
    toDateOnly(date) {
        var formatDate = new Date(date)
        return formatDate.setHours(0, 0, 0, 0)
    }

    //Get end of day
    toEndOfDay(date) {
        var formatDate = new Date(date)
        return formatDate.setHours(23, 59, 59)
    }

    //Convert date to string with format dd/mm/yyyy
    convertDateToString(date, formatString) {
        return format(date, formatString)
    }

    //Get minutes and seconds string by seconds duration
    getTimerDisplay(duration) {
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }
    /* ====================== */

    /* POPUPS */
    //Welcome notice
    showWelcomeNotice(companyInfo, setPopup) {
        let todayDate = this.toDateOnly(Date.now())
        let isStopShowingToday = window.localStorage.getItem(`${Constants.keys_welcome_notice}${todayDate}`) ?? 'false'
        let notice = companyInfo?.company?.notice ?? ''
        if (isStopShowingToday === 'true' || notice.length === 0) {
            return
        }

        setPopup(popup => ({
            ...popup,
            children: <WelcomeNotice />,
            showCloseButton: false,
            isOpen: true
        }))
    }

    //Forgot Password Popup
    showForgotPasswordPopup({ companyInfo, setPopup, setAlertDialog, navigate, setOpenForgotPwd }) {
        //Check settings for registration methods
        let company = companyInfo?.company
        let memberRegistrationMethod = company?.memberRegistrationMethod
        const templateDesign = company?.templateDesign ?? Constants.templateDesignClassic

        const goToForgotPassword = () => {
            if (templateDesign === Constants.templateDesignModern) {
                setOpenForgotPwd(true)
            } else {
                navigate('/login/forgotPassword')
            }
        }
        const forgotPasswordChecking = () => {
            const client = request({
                setAlertDialog: setAlertDialog
            })
            client.create('forgot-password-checking', {
                company: company?._id,
            }, null, (response) => {
                let link = response?.link ?? ''

                if (link.length > 0) {
                    window.open(link, '_self')?.focus()
                } else {
                    goToForgotPassword()
                }
            })
        }

        switch (memberRegistrationMethod) {
            case Constants.memberRegistrationMethodsWhatsappOnly:
            case Constants.memberRegistrationMethodsWhatsappOnlyNoReply:
            case Constants.memberRegistrationMethodsWhatsAppNoReplyAndTelegram:
            case Constants.memberRegistrationMethodsTelegramOnly:
            case Constants.memberRegistrationMethodsTelegramAndSMSRegister:
            case Constants.memberRegistrationMethodsLineOnly:
                forgotPasswordChecking()

                break
            case Constants.memberRegistrationMethodsWhatsappAndSMSRegister:
                //Whatsapp and SMS
                setPopup(popup => ({
                    ...popup,
                    children: (
                        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }} >
                            <div style={{ fontSize: '14px' }} >
                                <b>RESET PASSWORD OPTIONS</b>
                            </div>
                            <div style={{ height: '20px' }} />
                            <Stack direction='column' spacing='10px'>
                                <BaseButton title='SMS' onTap={() => {
                                    setPopup(popup => ({
                                        ...popup,
                                        isOpen: false,
                                        children: null,
                                        shouldNotClosePopupOnBackClick: false,
                                        onClosed: () => { }
                                    }))

                                    goToForgotPassword()
                                }} />
                                <BaseButton title='WHATSAPP' color={Constants.buttonGreenColor} onTap={() => {
                                    setPopup(popup => ({
                                        ...popup,
                                        isOpen: false,
                                        children: null,
                                        shouldNotClosePopupOnBackClick: false,
                                        onClosed: () => { }
                                    }))

                                    forgotPasswordChecking()
                                }} />
                            </Stack>
                        </div>
                    ),
                    showCloseButton: false,
                    isOpen: true
                }))
                break
            default:
                //SMS only
                goToForgotPassword()
                break
        }
    }

    //Register Popup
    showRegisterPopup({ companyInfo, setAlertDialog, setPopup, navigate, referralCode = '', setOpenRegister }) {
        //Check settings for registration methods
        const company = companyInfo?.company
        const design = company?.templateDesign ?? Constants.templateDesignClassic
        const memberRegistrationMethod = company?.memberRegistrationMethod
        const whatsappMethod = 'whatsapp'
        const telegramMethod = 'telegram'
        const lineMethod = 'line'

        const redirectToLink = (selectedMethod = whatsappMethod) => {
            var data = {
                company: companyInfo?.company?._id
            }

            let storedReferralCode = Utils.shared.retrieveLocalStorage(Constants.keys_referral_code)

            if (referralCode?.length > 0) {
                data['referralCode'] = referralCode
            } else if (storedReferralCode) {
                data['referralCode'] = storedReferralCode
            }

            const client = request({
                setAlertDialog: setAlertDialog
            })
            client.create('get-company-whatsapp-link', data, null, (response) => {
                let whatsappLink = response?.whatsappLink ?? ''
                let telegramLink = response?.telegramLink ?? ''
                let lineLink = response?.lineLink ?? ''
                var redirectLink = ''

                switch (selectedMethod) {
                    case whatsappMethod:
                        redirectLink = whatsappLink
                        break
                    case telegramMethod:
                        redirectLink = telegramLink
                        break
                    case lineMethod:
                        redirectLink = lineLink
                        break
                    default:
                        break
                }

                if (redirectLink.length > 0) {
                    //Got link, run as usual
                } else {
                    //No link, begin checking other link
                    if (whatsappLink.length > 0) {
                        redirectLink = whatsappLink
                    } else if (telegramLink.length > 0) {
                        redirectLink = telegramLink
                    } else if (lineLink.length > 0) {
                        redirectLink = lineLink
                    } else {
                        setAlertDialog(alertDialog => ({
                            ...alertDialog,
                            description: 'No BOT found',
                            isOpen: true,
                        }))

                        return
                    }
                }

                window.open(redirectLink, '_self')?.focus()
            })
        }

        switch (memberRegistrationMethod) {
            case Constants.memberRegistrationMethodsWhatsappOnly:
            case Constants.memberRegistrationMethodsWhatsappOnlyNoReply:
                redirectToLink(whatsappMethod)

                break
            case Constants.memberRegistrationMethodsTelegramOnly:
                redirectToLink(telegramMethod)

                break
            case Constants.memberRegistrationMethodsLineOnly:
                redirectToLink(lineMethod)

                break
            case Constants.memberRegistrationMethodsWhatsAppNoReplyAndTelegram:
            case Constants.memberRegistrationMethodsWhatsappAndSMSRegister:
            case Constants.memberRegistrationMethodsWhatsappNoReplyAndSMSRegister:
            case Constants.memberRegistrationMethodsTelegramAndSMSRegister:
            case Constants.memberRegistrationMethodsSMSRegisterAndLine:
                if (design === Constants.templateDesignModern && (
                    memberRegistrationMethod === Constants.memberRegistrationMethodsWhatsappAndSMSRegister ||
                    memberRegistrationMethod === Constants.memberRegistrationMethodsWhatsappNoReplyAndSMSRegister ||
                    memberRegistrationMethod === Constants.memberRegistrationMethodsTelegramAndSMSRegister ||
                    memberRegistrationMethod === Constants.memberRegistrationMethodsSMSRegisterAndLine
                )) {
                    setOpenRegister(true)
                } else {
                    setPopup(popup => ({
                        ...popup,
                        children: (
                            <RegisterPopup memberRegistrationMethod={memberRegistrationMethod} referralCode={referralCode} onTap={(method) => {
                                redirectToLink(method)
                            }} />
                        ),
                        showCloseButton: false,
                        isOpen: true
                    }))
                }

                break
            default:
                //SMS only
                if (design === Constants.templateDesignModern) {
                    setOpenRegister(true)
                } else {
                    if (referralCode?.length > 0) {
                        navigate(`/register?referralCode=${referralCode}`)
                    } else {
                        navigate('/register')
                    }
                }
                break
        }
    }

    //Claim Rebate Popup
    showClaimRebatePopup({ playerProfile, companyInfo, setCompanyInfo, setUserInfo, setAlertDialog, navigate, onClaimed, setPopup }) {
        const rebateClaimAmount = playerProfile?.rebateClaimAmount ?? 0

        //Check rebate claim amount
        if (rebateClaimAmount > 0) {
            const client = request({
                setAlertDialog: setAlertDialog,
                setCompanyInfo: setCompanyInfo,
                setUserInfo: setUserInfo,
                navigate: navigate
            })

            client.createREST('get-player-site-company-game', {
                secretKey: 'thebestsecretkey',
                company: companyInfo?.company?._id
            }, (response) => {
                //Show popup
                setPopup(popup => ({
                    ...popup,
                    children: (
                        <ClaimRebatePopup result={response} onClaimed={onClaimed} />
                    ),
                    dynamicWidth: true,
                    showCloseButton: false,
                    isOpen: true
                }))
            })
        }
    }

    navigateLogin({ companyInfo, navigate, setOpenLogin }) {
        const design = companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic
        if (design === Constants.templateDesignModern) {
            setOpenLogin(true)
        } else {
            navigate('/login')
        }
    }
    /* ====================== */

    // NOTIFICATION METHODS
    showNotification(title, message, companyInfo, onClicked = null) {
        let defaultWebsiteIcon = `${process.env.PUBLIC_URL}/logo192.png`
        let logoWebsite = companyInfo?.company?.logoWebsite ?? ''

        var iconLink
        if (logoWebsite.length > 0) {
            var faviconLink = Utils.shared.getCompanyImagesLink(logoWebsite, companyInfo?.company._id)
            iconLink = faviconLink
        } else {
            iconLink = defaultWebsiteIcon
        }

        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            //alert("This browser does not support desktop notification")
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            const notification = new Notification(title, {
                body: message,
                icon: iconLink,
            })
            notification.onclick = (event) => {
                window.parent.parent.focus()

                if (onClicked) {
                    onClicked()
                }

                notification.close()
            }
            // …
        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    const notification = new Notification(title, {
                        body: message,
                        icon: iconLink,
                    })
                    notification.onclick = (event) => {
                        window.parent.parent.focus()

                        if (onClicked) {
                            onClicked()
                        }

                        notification.close()
                    }
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
    }

    // WINDOW METHODS
    openWindowWithPost(url, data, target = '_self') {
        var form = document.createElement("form");
        form.target = target;
        form.method = "POST";
        form.action = url;
        form.style.display = "none";

        for (var key in data) {
            var input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = data[key];
            form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    // Get Player Site Company info
    getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, onSuccess, showLoading = true) {
        var domain = window.location.host
        if (window.location.host.indexOf('localhost') > -1 ||
            window.location.port === '3000') {
            //Map to this domain for testing
            domain = Constants.testingDomain
        }

        const client = request({
            setAlertDialog: setAlertDialog,
            setCompanyInfo: setCompanyInfo,
            setUserInfo: setUserInfo,
            navigate: navigate
        })
        client.create('get-player-site-company-info', {
            secretKey: 'thebestsecretkey',
            domain: domain,
            deviceId: this.getDeviceID(),
        }, null, (response) => {
            setCompanyInfo(response)

            const company = response?.company

            //Set website logo
            let logoWebsite = company?.logoWebsite ?? ''
            let defaultWebsiteIcon = `${process.env.PUBLIC_URL}/logo192.png`

            var iconLink;
            if (logoWebsite.length > 0) {
                var faviconLink = `${Utils.shared.getCompanyImagesLink(response, logoWebsite, company._id)}?v=${Date.now()}`
                iconLink = faviconLink;
            } else {
                iconLink = defaultWebsiteIcon;
            }

            //Create dynamic manifest and assign to html
            const manifestElement = document.getElementById("manifest");
            const manifestString = JSON.stringify({
                ...manifest,
                "name": company?.SEOTitle ?? '',
                "short_name": company?.SEOTitle ?? '',
                "description": company?.metaDescription ?? '',
                "apple-mobile-web-app-title": company?.SEOTitle ?? '',
                //"start_url": `https://${window.location.host}`,
                "display": "standalone",
                "background_color": "#000000",
                "theme_color": company?.metaThemeColor ?? "#000000",
                "orientation": "portrait-primary",
                "prefer_related_applications": false,
                "icons": [
                    {
                        "src": iconLink,
                        "sizes": "48x48",
                        "type": "image/png",
                        "purpose": "any maskable",
                    },
                    {
                        "src": iconLink,
                        "sizes": "72x72",
                        "type": "image/png",
                        "purpose": "any maskable",
                    },
                    {
                        "src": iconLink,
                        "sizes": "96x96",
                        "type": "image/png",
                        "purpose": "any",
                    },
                    {
                        "src": iconLink,
                        "sizes": "144x144",
                        "type": "image/png",
                        "purpose": "any maskable",
                    },
                    {
                        "src": iconLink,
                        "sizes": "168x168",
                        "type": "image/png",
                        "purpose": "any maskable",
                    },
                    {
                        "src": iconLink,
                        "sizes": "192x192",
                        "type": "image/png",
                        "purpose": "any maskable",
                    },
                    {
                        "src": iconLink,
                        "sizes": "512x512",
                        "type": "image/png",
                        "purpose": "any maskable",
                    },
                ],
            });
            manifestElement?.setAttribute(
                "href",
                "data:application/json;charset=utf-8," + encodeURIComponent(manifestString)
            );

            //Store companyID
            window.localStorage.setItem(`${Constants.keys_company_id}${window.location.host}`, company?._id)

            //Store user object if returned
            if (setUserInfo) {
                if (response.user) {
                    setUserInfo(response.user)
                } else {
                    setUserInfo(null)
                    window.localStorage.removeItem(`FEATHERSJS_ACCESS_TOKEN${window.location.host}`)
                }
            }

            onSuccess()
        }, null, showLoading)
    }

    //Calls Clear Player Credit Balance Api
    performClearPlayerCreditBalance({
        setAlertDialog,
        setCompanyInfo,
        setUserInfo,
        navigate,
        userInfo,
        onSuccess
    }) {
        const client = request({
            setAlertDialog: setAlertDialog,
            setCompanyInfo: setCompanyInfo,
            setUserInfo: setUserInfo,
            navigate: navigate
        })
        client.create('clear-player-credit-balance', {
            user: userInfo?._id,
        }, null, (response) => {
            onSuccess()
        })
    }
    getClearBalanceButton = ({ companyInfo, userInfo, template, setOpenClearBalance, translator }) => {
        let isAllowClearPlayerCreditBalance = companyInfo?.isAllowClearPlayerCreditBalance ?? false
        let playerVirtualCredits = userInfo?.playerVirtualCredits ?? 0

        if (isAllowClearPlayerCreditBalance && playerVirtualCredits > 0) {
            if (template.design === templateDesignModern) {
                return (
                    <Button sx={{
                        padding: '0px 16px',
                        paddingBottom: '16px',
                        width: '100%'
                    }} onClick={() => {
                        setOpenClearBalance(true)
                    }}>
                        <Stack spacing='8px' alignItems={'center'} justifyContent={'start'} direction={'row'} sx={{
                            color: '#828282',
                            width: '100%'
                        }}>
                            <CleaningServices />
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '22px',
                            }}>
                                {translator('GLOBAL_CLEAR_BALANCE')}
                            </Typography>
                        </Stack>
                    </Button>
                )
            } else {
                return (
                    <Stack alignItems={'start'}>
                        <Button sx={{ textTransform: 'none', padding: 0, fontSize: '12px', color: template.mainFontColor, textDecoration: 'underline' }} onClick={() => {
                            setOpenClearBalance(true)
                        }}>
                            {translator('GLOBAL_CLEAR_BALANCE')}
                        </Button>
                    </Stack>
                )
            }
        } else {
            return false
        }
    }

    //CHECKING METHODS
    supportsBSBNumber(companyInfo) {
        const countryCode = companyInfo?.company?.countryCode

        switch (countryCode) {
            case Constants.countryCodeAU:
                return true
            default:
                return false
        }
    }

    //Navigation
    navigateDeposit(userInfo, companyInfo, setAlertDialog, navigate) {
        const templateDesign = companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic

        if (userInfo) {
            //Check if bank account is required
            let memberBankAccountRequired = companyInfo?.company?.memberBankAccountRequired ?? true
            if (memberBankAccountRequired) {
                Utils.shared.getPlayerWithdrawalAccounts(companyInfo, userInfo, setAlertDialog, (withdrawalBanks, playerWithdrawalAccounts) => {
                    if (playerWithdrawalAccounts && playerWithdrawalAccounts.length > 0) {
                        if (templateDesign === Constants.templateDesignModern) {
                            navigate('/profile#deposit')
                        } else {
                            navigate('deposit')
                        }
                    } else {
                        if (templateDesign === Constants.templateDesignModern) {
                            navigate('/profile#details?redirect=/profile#deposit')
                        } else {
                            navigate('/profile/bankAccount?redirect=/deposit')
                        }
                    }
                })
            } else {
                //Bank account not required, proceed with deposit
                if (templateDesign === Constants.templateDesignModern) {
                    navigate('/profile#deposit')
                } else {
                    navigate('deposit')
                }
            }
        } else {
            navigate('/login')
        }
    }
    navigateWithdrawal(userInfo, companyInfo, setAlertDialog, navigate) {
        const templateDesign = companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic

        if (userInfo) {
            Utils.shared.getPlayerWithdrawalAccounts(companyInfo, userInfo, setAlertDialog, (withdrawalBanks, playerWithdrawalAccounts) => {
                if (playerWithdrawalAccounts && playerWithdrawalAccounts.length > 0) {
                    if (templateDesign === Constants.templateDesignModern) {
                        navigate('/profile#withdraw')
                    } else {
                        navigate('withdrawal')
                    }
                } else {
                    if (templateDesign === Constants.templateDesignModern) {
                        navigate('profile?redirect=/profile#withdraw')
                    } else {
                        navigate('profile/bankAccount?redirect=/deposit')
                    }
                }
            })
        } else {
            navigate('/login')
        }
    }
}

export default Utils